<template>
  <div class="wrap">
    <p class="title">无人机任务列表</p>
    <!-- 搜索 -->
    <a-form layout="inline">
      <a-form-item label="任务名称">
        <a-input v-model:value.trim="taskName" placeholder="请输入任务名称" size="small" allowClear></a-input>
      </a-form-item>
      <a-form-item label="计划开始时间">
        <a-range-picker size="small" v-model:value="rangeDate" />
      </a-form-item>
      <!-- <a-form-item label="执飞人">
        <a-input v-model:value.trim="flyer" placeholder="请输入执飞人" size="small" allowClear></a-input>
      </a-form-item> -->
      <a-form-item class="btn-group">
        <a-button type="primary" size="small" @click="search">查询</a-button>
        <a-button size="small" @click="reset">重置</a-button>
      </a-form-item>
    </a-form>
    <!-- 操作 -->
    <div class="action">
      <!-- <a-button type="primary" size="small" @click="jumpToUavManagePage">无人机管理</a-button> -->
      <a-button size="small" @click="showAddEditModal" v-if="getFnCodeList.includes('UAV_TASK_INSERT')">新增任务</a-button>
    </div>
    <!-- 表格 -->
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="pagination"
      :row-key="record => record.id"
      @change="handlePageChange"
      :scroll="{ x: 1800 }"
      ref="tableRef"
    >
      <template #idx="row">
        {{ (pagination.current - 1) * pagination.pageSize + row.index + 1 }}
      </template>

      <template #videoResource="row">
        <a-button type="link" @click="view(row)">查看视频</a-button>
      </template>

      <template #flyTime="row">
        <span v-if='Boolean(row.record.flyTime)'>{{ row.record.flyTime }} 天</span>
      </template>

      <template #flyPic="row">
        <a-image
          :src="row.record.flyPic"
        />
      </template>
      
      <template #status="row">
        {{ taskStatus[row.record.status] }}
      </template>
      <template #action="row">
        <div
          class="action"
          v-if="
            getFnCodeList && getFnCodeList.includes('UAV_TASK_APPROVE')
              ? true
              : row.record.createId == getSS_userinfo.id
              ? true
              : false
          "
        >
          <span
            class="edit"
            @click="showAddEditModal(row)"
            v-if="
              getFnCodeList && getFnCodeList.includes('UAV_TASK_APPROVE')
                ? true
                : row.record.status === 1
                ? false
                : true
            "
            >{{
              getFnCodeList && getFnCodeList.includes('UAV_TASK_APPROVE')
                ? '查看'
                : row.record.status === 0
                ? '编辑'
                : '查看'
            }}</span
          >
          <span
            class="del"
            @click="del(row)"
            v-if="
              (row.record.status === 3 || row.record.status === 0) &&
                !(getFnCodeList && getFnCodeList.includes('UAV_TASK_APPROVE'))
            "
            >删除</span
          >
          <span
            v-if="row.record.status === 0 && getFnCodeList && getFnCodeList.includes('UAV_TASK_APPROVE')"
            class="approve"
            @click="approve(row.record.id)"
            >审核</span
          >

          <span
            v-if="row.record.status === 1 && !(getFnCodeList && getFnCodeList.includes('UAV_TASK_APPROVE'))"
            class="edit"
            @click="finish(row)"
            >完成</span
          >
        </div>
        <div class="action" v-else>
          <span class="edit" @click="showAddEditModal(row)">查看</span>
          <span class="edit" v-if="getSS_userinfo.feishou" v-show="row.record.status === 1 || row.record.status === 2" @click="showAddEditFlyModal(row)">编辑工时</span>
        </div>

      </template>
    </a-table>
  </div>
  <a-modal :visible="approveVisible" @cancel="approveVisible = false">
    <p class="">请修改该条任务状态</p>
    <a-textarea v-model:value="checkOpinion" placeholder="请输入审批意见" allow-clear />
    <template #footer>
      <a-button @click="approveVisible = false">取消</a-button>
      <a-button type="primary" @click="approveFinish(true)">通过</a-button>
      <a-button type="danger" @click="approveFinish(false)">不通过</a-button>
    </template>
  </a-modal>
  <add-edit-task-modal ref="AddEditTaskRef" @addOrUpdateSuccess="search"></add-edit-task-modal>
  <add-edit-fly-modal ref="AddEditFlyRef" @addOrUpdateSuccess="search"></add-edit-fly-modal>

  <a-modal :visible="visible" title="查看视频" @cancel="handleCancel" :footer="null">
    <div id="mse-video"></div>
  </a-modal>

  <a-modal :visible="endTimeVisible" title="" @ok="handleOk" @cancel="endTimeVisible = false">
    请选择结束日期
    <a-date-picker v-model:value="endTime" show-time format="YYYY-MM-DD HH:mm:ss" placeholder="结束时间" />
  </a-modal>
</template>

<script>
import { defineComponent, ref, toRefs, createVNode, reactive, onMounted, nextTick, computed, onUpdated } from 'vue';
import { useRouter } from 'vue-router';
import api from '@/services';
import moment from 'moment';
import { Modal, message } from 'ant-design-vue';
import AddEditTaskModal from './AddEditTaskModal';
import AddEditFlyModal from './AddEditFlyModal';
import HlsPlayer from 'xgplayer-hls';
import { FormatTime } from '@/utils/tool';
//状态 0：待审核，1：已审核，2：已执行，3: 审核未通过
const taskStatus = ['待审核', '已审核', '已执行', '审核未通过'];

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    // fixed: 'left',
    align: 'center',
    width: 80,
    slots: {
      customRender: 'idx'
    }
  },
  {
    title: '任务名称',
    dataIndex: 'taskName',
    align: 'center',
    width: 100,
    ellipsis: true
  },
  {
    title: '开始时间',
    dataIndex: 'startTime',
    width: 100,
    align: 'center'
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    width: 100,
    align: 'center'
  },
  {
    title: '联系人姓名',
    width: 120,
    dataIndex: 'contactName',
    align: 'center'
  },
  {
    title: '联系人电话',
    width: 120,
    dataIndex: 'contactPhone',
    align: 'center'
  },
  // {
  //   title: '视频回看地址',
  //   dataIndex: 'videoResource',
  //   align: 'center',
  //   ellipsis: true,
  //   slots: {
  //     customRender: 'videoResource'
  //   }
  // },
  {
    title: '任务状态',
    dataIndex: 'status',
    width: 100,
    align: 'center',
    slots: {
      customRender: 'status'
    }
  },
  {
    title: '审核意见',
    dataIndex: 'checkOpinion',
    width: 100,
    align: 'center'
  },
  {
    title: '申请人',
    dataIndex: 'createNickName',
    width: 100,
    align: 'center'
  },
  {
    title: '所在街道',
    dataIndex: 'departmentName',
    width: 100,
    align: 'center'
  },

  {
    title: '飞行时长',
    dataIndex: 'flyTime',
    width: 100,
    align: 'center',
    slots: {
      customRender: 'flyTime'
    }
  },
  {
    title: '飞行记录',
    dataIndex: 'flyContent',
    width: 100,
    align: 'center'
  },
  {
    title: '飞行记录图片',
    dataIndex: 'flyPic',
    width: 120,
    align: 'center',
    slots: {
      customRender: 'flyPic'
    }
  },
  {
    title: '实际飞行开始时间',
    dataIndex: 'start',
    width: 120,
    align: 'center'
  },
  {
    title: '实际飞行结束时间',
    dataIndex: 'end',
    width: 120,
    align: 'center'
  },
  // {
  //   title: '备注',
  //   dataIndex: 'status',
  //   align: 'center',
  // },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 150,
    // fixed: 'right',
    // ellipsis: true, // 设置为true 可以隐藏超出部分
    align: 'center',
    slots: {
      customRender: 'action'
    }
  }
];
export default defineComponent({
  components: {
    AddEditTaskModal,
    AddEditFlyModal
  },
  setup() {
    onMounted(() => {
      getUavTaskList();
    });

    const tableRef = ref(null)
    onUpdated(() => {
      nextTick(() => {
        console.log(tableRef.value.$el)
      });
    })

    const endTimeVisible = ref(false);

    const getFnCodeList = computed(() => {
      return JSON.parse(localStorage.getItem('fnCodeList'));
    });

    const getSS_userinfo = computed(() => {
      return JSON.parse(localStorage.getItem('SS_userinfo'));
    });

    // console.log(getSS_userinfo.value.feishou)
    // 审批弹窗 显示/隐藏
    const approveVisible = ref(false);

    // 用于审核的任务 id
    const taskId = ref();

    const AddEditTaskRef = ref();
    const AddEditFlyRef = ref();
    const isEdit = ref(false);

    const router = useRouter();

    const videoUrl = ref('');
    const visible = ref(false);

    const playerState = reactive({
      player: {}
    });

    // table 相关变量
    const tableState = reactive({
      dataSource: [],
      columns
    });

    // 条件筛选表单
    const formState = reactive({
      taskName: '',
      // flyer: '',
      rangeDate: []
    });

    const pagination = ref({
      pageSize: 5,
      total: 0,
      current: 1,
      showQuickJumper: true
    });

    // 获取预警列表 table data
    const getUavTaskList = async () => {
      const params = {
        ...formState,
        startTime: formState.rangeDate.length ? moment(formState.rangeDate[0]).format('YYYY-MM-DD') + ' 00:00:00' : '',
        endTime: formState.rangeDate.length ? moment(formState.rangeDate[1]).format('YYYY-MM-DD') + ' 23:59:59' : '',
        curPage: pagination.value.current,
        pageSize: pagination.value.pageSize
      };
      delete params.rangeDate;

      const { data, success } = await api.getUavTaskList(params);
      if (success) {
        tableState.dataSource = data.records;
        pagination.value.total = data.total;
      }
    };

    // 显示 新增 / 编辑 弹窗
    const showAddEditModal = row => {
      let rowData;
      if (row.record) {
        isEdit.value = true;
        rowData = row.record;
        const fnCodeList = JSON.parse(localStorage.getItem('fnCodeList'));
        if (fnCodeList && fnCodeList.includes('UAV_TASK_APPROVE')) {
          AddEditTaskRef.value.showModal(isEdit.value, rowData, '查看');
        } else {
          // console.log(row.record.createId, getSS_userinfo.value)
          if (row.record.createId == getSS_userinfo.value.id) {
            AddEditTaskRef.value.showModal(isEdit.value, rowData, row.record?.status === 0 ? '编辑' : '查看');
          } else {
            AddEditTaskRef.value.showModal(isEdit.value, rowData, '查看');
          }
        }
      } else {
        isEdit.value = false;
        AddEditTaskRef.value.showModal(isEdit.value, rowData);
      }
    };

    const showAddEditFlyModal = row => {
      isEdit.value = true;
      AddEditFlyRef.value.showModal(isEdit.value, row.record);
    }

    // 创建播放器
    const createPlayer = () => {
      playerState.player = new HlsPlayer({
        id: 'mse-video',
        url: videoUrl.value,
        autoplay: true
      });
      document.getElementById('mse-video').style.width = '100%';
    };

    // 销毁播放器
    const destroyPlayer = () => {
      console.log('player==', playerState.player);
      playerState.player.destroy();
    };

    // 删除
    const del = row => {
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;'
          },
          `确认要删除该任务吗？`
        ),

        async onOk() {
          let { success, msg } = await api.delTaskById(row.record.id);
          if (success) {
            message.success('删除成功！');
            search();
          } else {
            message.error(msg);
          }
        }
      });
    };

    // 完成
    const row_ref = ref(null);
    const finish = row => {
      row_ref.value = row;
      endTimeVisible.value = true;
    };

    const approve = id => {
      approveVisible.value = true;
      taskId.value = id;
    };

    const checkOpinion = ref('');
    // 审批通过/不通过
    const approveFinish = async flag => {
      let params = {
        id: taskId.value,
        status: flag ? 1 : 3,
        checkOpinion: checkOpinion.value
      };
      let { success, msg } = await api.updateUavTask(params);
      if (success) {
        message.success('操作成功！');
        approveVisible.value = false;
        search();
      } else {
        message.error(msg);
      }
    };

    // 跳转到无人机管理页面
    const jumpToUavManagePage = () => {
      router.push('/admin/machine-manage/uav-manage');
    };

    const handleCancel = () => {
      visible.value = false;
      destroyPlayer();
    };

    // 查看详情
    const showDetailModal = () => {};

    const handlePageChange = page => {
      pagination.value.current = page.current;
      getUavTaskList();
    };

    // 查看视频
    const view = row => {
      visible.value = true;
      videoUrl.value = row.text;
      nextTick(() => {
        createPlayer();
      });
    };

    const search = () => {
      pagination.value.current = 1;
      getUavTaskList();
    };

    const reset = () => {
      Object.keys(formState).map(key => (formState[key] = ''));
      search();
    };

    const endTime = ref('');
    const handleOk = async () => {
      if (!endTime.value) {
        message.error('请输入完成时间');
        return false;
      }
      let params = {
        id: row_ref.value.record.id,
        status: 2,
        endTime: FormatTime(endTime.value, 'YYYY-MM-DD HH:mm:ss')
      };
      let { success, msg } = await api.updateUavTask(params);
      if (success) {
        message.success('状态修改成功！');
        endTimeVisible.value = false;
        search();
      } else {
        message.error(msg);
      }
    };

    return {
      getFnCodeList,
      approveVisible,
      approveFinish,
      taskStatus,
      AddEditTaskRef,
      AddEditFlyRef,
      showAddEditModal,
      isEdit,
      visible,
      videoUrl,
      ...toRefs(formState),
      getUavTaskList,
      handlePageChange,
      search,
      reset,
      del,
      approve,
      finish,
      view,
      playerState,
      createPlayer,
      destroyPlayer,
      handleCancel,
      showDetailModal,
      jumpToUavManagePage,
      ...toRefs(tableState),
      checkOpinion,
      pagination,
      getSS_userinfo,
      endTimeVisible,
      handleOk,
      endTime,
      showAddEditFlyModal,

      tableRef
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .action {
    display: flex;
    justify-content: flex-start;
    .ant-btn {
      margin-right: 20px;
    }
  }
  :deep(.ant-form) {
    display: flex;
    justify-content: center;
    margin: 15px 0;
    .btn-group {
      flex: 1;
      .ant-btn {
        margin-left: 20px;
      }
    }
  }
  :deep(.ant-table-wrapper) {
    margin-top: 15px;
    .ant-table {
      .avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .action > span {
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }
      .action > span:not(:last-child) {
        margin-right: 14px;
      }

      .action {
        display: flex;
        span {
          cursor: pointer;
          color: #fff;
        }
        .edit {
          white-space: nowrap;
          background-color: #007fff;
        }
        .del {
          background-color: #d71345;
        }
        .approve {
          background-color: #faa755;
        }
      }
    }
  }

  :deep(.ant-table-wrapper) {
    table {
      width: unset;
    }
    .ant-table-fixed,.ant-table-body-outer,.ant-table-fixed-right{
      height: 100%
    }
    
  }
}


</style>
